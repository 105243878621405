export const consentFormTemplateStringDefault = `(patient, hospitalInfo, ref) => {
    const formatDate = (dateString) => {
      if (!dateString) return "";
      const date = new Date(dateString);
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      return \`\${date.getDate().toString().padStart(2, '0')}-\${months[date.getMonth()]}-\${date.getFullYear()}\`;
    };
  
  
    return React.createElement("div", { ref: ref, className: "print-content", style : {marginTop : "20px"} },
      React.createElement("div",null,
        React.createElement(HospitalHeader, { hospitalInfo: hospitalInfo })
      ),
      React.createElement("div", { style: { display: "flex", justifyContent: "center", marginBottom: "12px", marginTop : "10px" } },
        React.createElement("span", { style: { textDecoration: "underline", textDecorationThickness: "2px", textUnderlineOffset: "4px", fontSize: "20px" } }, "Admission Form")
      ),
      React.createElement("div", { style: { display: "grid", gridTemplateColumns: "1fr 1fr" } },
        React.createElement("div", { style: { display: "grid", gridTemplateColumns: "repeat(3, 1fr)" } },
          React.createElement("span", null, "Patient Name:"),
          React.createElement("span", { style: { fontWeight: "600", textTransform: "capitalize", gridColumn: "span 2" } }, patient?.patient?.name || "")
        ),
        React.createElement("div", { style: { display: "grid", gridTemplateColumns: "repeat(3, 1fr)" } },
          React.createElement("span", null, "Guardian Name:"),
          React.createElement("span", { style: { fontWeight: "600", textTransform: "capitalize", gridColumn: "span 2" } }, patient?.guardianName || "")
        ),
        React.createElement("div", { style: { display: "grid", gridTemplateColumns: "repeat(3, 1fr)" } },
          React.createElement("span", null, "UHID Number:"),
          React.createElement("span", { style: { fontWeight: "600", textTransform: "capitalize", gridColumn: "span 2" } }, patient?.registrationNumber || "")
        ),
        React.createElement("div", { style: { display: "grid", gridTemplateColumns: "repeat(3, 1fr)" } },
          React.createElement("span", null, "IPD Number:"),
          React.createElement("span", { style: { fontWeight: "600", textTransform: "capitalize", gridColumn: "span 2" } }, patient?.ipdNumber || "")
        ),
        React.createElement("div", { style: { display: "grid", gridTemplateColumns: "repeat(3, 1fr)" } },
          React.createElement("span", null, "Room No:"),
          React.createElement("span", { style: { fontWeight: "600", textTransform: "capitalize", gridColumn: "span 2" } }, patient?.assignedRoom?.roomNumber || "")
        ),
        React.createElement("div", { style: { display: "grid", gridTemplateColumns: "repeat(3, 1fr)" } },
          React.createElement("span", null, "Age:"),
          React.createElement("span", { style: { fontWeight: "600", textTransform: "capitalize", gridColumn: "span 2" } }, patient?.patient?.age ? \`\${patient?.patient?.age} Years\` : "")
        ),
        React.createElement("div", { style: { display: "grid", gridTemplateColumns: "repeat(3, 1fr)" } },
          React.createElement("span", null, "Gender:"),
          React.createElement("span", { style: { fontWeight: "600", textTransform: "capitalize", gridColumn: "span 2" } }, patient?.patient?.gender || "")
        ),
        React.createElement("div", { style: { display: "grid", gridTemplateColumns: "repeat(3, 1fr)" } },
          React.createElement("span", null, "Address:"),
          React.createElement("span", { style: { fontWeight: "600", textTransform: "capitalize", gridColumn: "span 2" } }, patient?.patient?.address || "")
        ),
        React.createElement("div", { style: { display: "grid", gridTemplateColumns: "repeat(3, 1fr)" } },
          React.createElement("span", null, "Mobile No:"),
          React.createElement("span", { style: { fontWeight: "600", textTransform: "capitalize", gridColumn: "span 2" } }, patient?.patient?.contactNumber || "")
        ),
        React.createElement("div", { style: { display: "grid", gridTemplateColumns: "repeat(3, 1fr)" } },
          React.createElement("span", null, "Admission Date:"),
          React.createElement("span", { style: { fontWeight: "600", textTransform: "capitalize", gridColumn: "span 2" } },
            \`\${formatDate(patient?.bookingDate)} \${patient?.bookingTime || ""}\`
          )
        ),
        React.createElement("div", { style: { display: "grid", gridTemplateColumns: "repeat(3, 1fr)" } },
          React.createElement("span", null, "Consultant:"),
          React.createElement("span", { style: { fontWeight: "600", textTransform: "capitalize", gridColumn: "span 2" } }, patient?.doctor?.name || "")
        )
      ),
      React.createElement("div", { style: { margin: "8px 0", backgroundColor: "black", height: "1px" } }),
      React.createElement("div", { style: { textDecoration: "underline", fontWeight: "600", margin: "0 16px", textUnderlineOffset: "2px" } }, "Person to notify in case of Emergency"),
      React.createElement("div", { style: { display: "flex", justifyContent: "space-between", marginBottom: "16px", margin: "0 16px" } },
        React.createElement("div", { style: { textTransform: "capitalize" } }, \`Name: \${patient?.guardianName || ""}\`),
        React.createElement("div", null, \`Mobile No: \${patient?.patient?.contactNumber || ""}\`),
        React.createElement("div", { style: { textTransform: "capitalize" } }, \`Relation with Patient : \${patient?.relation || ""}\`)
      ),
      React.createElement("div", { style: { display : "flex", flexDirection : "column", gap : "12px", marginTop: "16px", textAlign: "justify", padding: "0 16px" } },
        React.createElement("p", { style: { fontSize: "18px", fontWeight: "600", marginBottom: "8px" } }, "सामान्य सहमति"),
        React.createElement("p", null,
          "मैं, __________________________________________ (रोगी/रिश्तेदार) सचेत मन की स्थिति में अस्पताल के सलाहकारों और पैरामेडिकल कर्मियों को चिकित्सा परीक्षण, पैथोलॉजिकल और रेडियोलॉजिकल और मेडिकल/सर्जिकल उपचार या किसी भी प्रकार की प्रक्रिया करने के लिए सहमति देता हूं और अधिकृत करता हूं।"
        ),
        React.createElement("p", null,
          "ओपीडी/आईपीडी में रोगी देखभाल के दौरान प्रशासनिक/बीमा उद्देश्य के लिए कागजात के दस्तावेजीकरण और नैदानिक अनुसंधान और गोपनीयता के लिए जानकारी के प्रकटीकरण के साथ सलाह दी जाती है। हम पूरी तरह संतुष्ट हैं और अपनी इच्छा से इलाज/सर्जरी कराना चाहते हैं।"
        ),
        React.createElement("p", null,
          "हम अस्पताल के बिल के समय पर भुगतान के लिए जिम्मेदार होंगे। उपचार/सर्जरी के दौरान और उसके दौरान किसी भी जटिलता के लिए मैं स्वयं जिम्मेदार रहूंगा। सर्जरी/उपचार से होने वाली किसी भी जटिलता और खतरे के लिए अस्पताल, अस्पताल कर्मचारी, डॉक्टर जिम्मेदार नहीं होंगे।"
        ),
        React.createElement("p", null,
          "अस्पताल और अस्पताल के कर्मचारी किसी भी चोरी हुए सामान के लिए जिम्मेदार नहीं होंगे, मैं अपने सामान की सुरक्षा के लिए जिम्मेदार हूं।"
        ),
        React.createElement("p", null,
          "मैंने ऊपर लिखी सभी बातें पढ़ी हैं और मुझे समझाया भी गया है।"
        ),
        React.createElement("p", null,
          "ऊपर दी गई सभी जानकारियों को समझने के बाद मैं अपनी अनुमति देता हूं।"
        )
      ),
      React.createElement("div", { style: { marginTop: "50px", display: "flex", justifyContent: "space-between", padding: "0 16px" } },
        React.createElement("div", { style: { textAlign: "center" } },
          React.createElement("p", { style: { borderTop: "1px solid black" } }, "Signature of Patient"),
          React.createElement("p", { style: { textTransform: "uppercase", fontSize: "12px" } }, \`(\${patient?.patient?.name || ""})\`)
        ),
        React.createElement("div", { style: { textAlign: "center" } },
          React.createElement("p", { style: { borderTop: "1px solid black" } }, "Signature of Guardian"),
          React.createElement("p", { style: { fontSize: "12px", textTransform: "uppercase" } }, patient?.guardianName ? \`(\${patient?.guardianName})\` : "")
        )
      )
    );
  }`;

export const consentFormTemplateStringExperimentation = `
(patient, hospitalInfo, ref) => {
    const formatDate = (dateString) => {
      if (!dateString) return "";
      const date = new Date(dateString);
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      return \`\${date.getDate().toString().padStart(2, '0')}-\${months[date.getMonth()]}-\${date.getFullYear()}\`;
    };

  const getConsentPoint = (index) => {
    const points = {
      1: \`I, the undersigned, a patient, next of kin (NOK/Ward) of patient in Kidney Stone And Urology Clinic, Bhagalpur authorise Dr. \${patient?.doctor?.name || "____________________"} and whomsoever he may designate to administer such treatment as necessary and perform the following medical treatment, surgical operation and diagnostic/therapeutic procedures ______________________\nमैं, अधोहस्ताक्षरी, किडनी स्टोन और यूरोलॉजी क्लिनिक , भागलपुर में उपचाराधीन रोगी स्वयं/ निकटतम संबंधी, डॉक्टर \${patient?.doctor?.name || "____________________"} निम्नलिखित चिकित्सा उपचार, सर्जिकल ऑपरेशन और नैदानिक/चिकित्सीय प्रक्रिया ______________________ करने की अनुमति देता हूँ।\`,
      2: \`The nature and purpose of the operation and /or procedure, the necessity thereof, the possible alternative methods of treatment, prognosis, the risks involved and possibility of complications known and unforeseen have all been fully explained to me to satisfaction and fully understand the same.\nऑपरेशन और/या प्रक्रिया की प्रकृति और उद्देश्य, इसकी आवश्यकता, उपचार के संभावित वैकल्पिक तरीके, रोग का निदान, इसमें शामिल जोखिम और ज्ञात और अप्रत्याशित जटिलताओं की संभावना सभी को मुझे संतुष्टि के लिए पूरी तरह से समझाया गया है और पूरी तरह से समझा गया है।\`,
      3: \`It has been explained to me that during the course of the above said operation/ procedure, unforeseen conditions may be revealed/ encountered with, which may necessitate additional surgical or other emergency procedures, different from those contemplated and consented to at the time of initial diagnosis. I therefore authorise the above designated staff to perform such additional surgical or other procedures as they deem necessary or desirable.\nमुझे यह समझाया गया है कि उपर्युक्त ऑपरेशन/प्रक्रिया के दौरान, अप्रत्याशित स्थितियों का पता चल सकता है/सामना किया जा सकता है, जिसके लिए अतिरिक्त सर्जिकल या अन्य आपातकालीन प्रक्रियाओं की आवश्यकता हो सकती है, जो प्रारंभिक निदान के समय चिंतन और सहमति से अलग हो सकती हैं। इसलिए मैं उपरोक्त नामित कर्मचारियों को ऐसी अतिरिक्त शल्य चिकित्सा या अन्य प्रक्रियाओं को करने के लिए अधिकृत करता हूं जैसा कि वे आवश्यक या वांछनीय समझते हैं।\`,
      4: \`I further give consent to administration of such drug, infusion, plasma and blood transfusion, or any other investigation, treatment, procedure as deemed necessary in judgement of the medical staff.\nमैं आगे ऐसी दवा, जलसेक, प्लाज्मा और रक्त आधान, या किसी अन्य जांच, उपचार, प्रक्रिया के प्रशासन के लिए सहमति देता हूं जैसा कि चिकित्सा कर्मचारियों के निर्णय में आवश्यक समझा जाता है।\`,
      6: \`I have also been explained that any operation or procedure involves risks and hazards which may be serious or even fatal. I hereby declare that in the event of any mishap, accident or complication I will not hold any member of the hospital team personally liable for any penal action.\nमुझे यह भी समझाया गया है कि किसी भी ऑपरेशन या प्रक्रिया में जोखिम और खतरे शामिल हैं जो गंभीर या घातक भी हो सकते हैं। मैं एतद्द्वारा घोषणा करता हूं कि किसी भी दुर्घटना, दुर्घटना या जटिलता की स्थिति में मैं अस्पताल टीम के किसी भी सदस्य को किसी भी दंडात्मक कार्रवाई के लिए व्यक्तिगत रूप से उत्तरदायी नहीं ठहराऊंगा।\`,
      7: \`I have given an opportunity to ask any question and I have also been given the option to ask for any second opinion.\nमैंने कोई भी प्रश्न पूछने का अवसर दिया है और मुझे कोई भी दूसरी राय पूछने का विकल्प भी दिया गया है।\`,
      8: \`I have been explained the following can be the complication during and after the operation.\nमुझे समझाया गया है कि ऑपरेशन के दौरान और बाद में निम्नलिखित जटिलता हो सकती है।\`,
      9: \`I acknowledge that no guarantee and promises have been made to me concerning that result of any operation/ treatment.\nमैं स्वीकार करता हूं कि किसी भी ऑपरेशन / उपचार के परिणाम के बारे में मुझसे कोई गारंटी और वादे नहीं किए गए हैं।\`,
      10: \`I consent to photographing/video recording of operation, which may be viewed for academic purpose only subject to the identify being adequately protected. I further give my consent to the professional and / or other information from medical records as deemed necessary in accordance with the rule and policies of the hospital.\nमैं ऑपरेशन की फोटोग्राफ/वीडियो रिकॉर्डिंग के लिए सहमति देता हूं, जिसे केवल शैक्षणिक उद्देश्य के लिए देखा जा सकता है, बशर्ते कि नाम पर्याप्त रूप से संरक्षित हो। मैं आगे अस्पताल के नियम और नीतियों के अनुसार आवश्यक समझे जाने वाले मेडिकल रिकॉर्ड से पेशेवर और/या अन्य जानकारी के लिए अपनी सहमति देता हूं।\`,
      11: \`I also give consent to disposal by hospital authorities of any diseased tissues or parts thereof which to be removed during the course of operative procedure /treatment.\nमैं अस्पताल के अधिकारियों द्वारा किसी भी रोगग्रस्त ऊतक या उसके कुछ हिस्सों के निपटान के लिए सहमति भी देता हूं जिन्हें ऑपरेशन प्रक्रिया/उपचार के दौरान हटाया जाना है।\`
    };
    return points[index] || "";
  };

  return React.createElement("div", { ref: ref, className: "print-content", style: {  padding: "20px", fontSize: "14px" } },
      React.createElement(HospitalHeader, { hospitalInfo: hospitalInfo }),

    // Title Section
    React.createElement("div", { style: { textAlign: "center", margin: "20px 0", fontWeight: "bold" } },
      React.createElement("h2", null, "CONSENT FORM/ सहमति पत्र"),
      React.createElement("h3", null, "PERMISSION FOR OPERATION AND PROCEDURE"),
      React.createElement("h3", null, "ऑपरेशन और प्रक्रिया के लिए अनुमति")
    ),

    // Patient Details Section
    React.createElement("div", { style: { marginBottom: "20px" } },
      React.createElement("table", { 
        style: { 
          width: "100%", 
          borderCollapse: "collapse",
          marginBottom: "20px"
        } 
      },
        React.createElement("tbody", null,
          React.createElement("tr", null,
            React.createElement("td", { 
              style: { 
                border: "1px solid black", 
                padding: "8px",
                width: "50%"
              } 
            }, 
              React.createElement("div", null,
                React.createElement("span", null, "Name/नाम:"),
                React.createElement("span", { 
                  style: { 
                    marginLeft: "10px",
                    borderBottom: "1px dotted black",
                    minWidth: "200px",
                    display: "inline-block"
                  } 
                }, patient?.patient?.name || "")
              )
            ),
            React.createElement("td", { 
              style: { 
                border: "1px solid black", 
                padding: "8px" 
              } 
            },
              React.createElement("div", null,
                React.createElement("span", null, "Age/उम्र:"),
                React.createElement("span", { 
                  style: { 
                    marginLeft: "10px",
                    borderBottom: "1px dotted black",
                    minWidth: "100px",
                    display: "inline-block"
                  } 
                }, patient?.patient?.age || "")
              )
            )
          ),
          React.createElement("tr", null,
            React.createElement("td", { 
              style: { 
                border: "1px solid black", 
                padding: "8px" 
              } 
            },
              React.createElement("div", null,
                React.createElement("span", null, "Gender/लिंग:"),
                React.createElement("span", { 
                  style: { 
                    marginLeft: "10px",
                    borderBottom: "1px dotted black",
                    minWidth: "200px",
                    display: "inline-block"
                  } 
                }, patient?.patient?.gender || "")
              )
            ),
            React.createElement("td", { 
              style: { 
                border: "1px solid black", 
                padding: "8px" 
              } 
            },
              React.createElement("div", null,
                React.createElement("span", null, "Registration No/रजिस्ट्रेशन नंबर:"),
                React.createElement("span", { 
                  style: { 
                    marginLeft: "10px",
                    borderBottom: "1px dotted black",
                    minWidth: "100px",
                    display: "inline-block"
                  } 
                }, patient?.registrationNumber || "")
              )
            )
          ),
          React.createElement("tr", null,
            React.createElement("td", { 
              style: { 
                border: "1px solid black", 
                padding: "8px" 
              } 
            },
              React.createElement("div", null,
                React.createElement("span", null, "Date/तारीख:"),
                React.createElement("span", { 
                  style: { 
                    marginLeft: "10px",
                    borderBottom: "1px dotted black",
                    minWidth: "200px",
                    display: "inline-block"
                  } 
                }, formatDate(patient?.bookingDate) || "")
              )
            ),
            React.createElement("td", { 
              style: { 
                border: "1px solid black", 
                padding: "8px" 
              } 
            },
              React.createElement("div", null,
                React.createElement("span", null, "Time/समय:"),
                React.createElement("span", { 
                  style: { 
                    marginLeft: "10px",
                    borderBottom: "1px dotted black",
                    minWidth: "100px",
                    display: "inline-block"
                  } 
                }, patient?.bookingTime || "")
              )
            )
          )
        )
      )
    ),

    // Informed Consent Section
    React.createElement("div", { style: { marginBottom: "20px" } },
      React.createElement("h3", { style: { textAlign: "center", marginBottom: "15px" } }, 
        "INFORMED CONSENT/ सूचित सहमति",
        React.createElement("p", { style: { fontSize: "14px", fontWeight: "normal", marginTop: "5px" } },
          "Authorisation for medical treatment and preformation of surgical operation and / diagnostic / therapeutic procedures.",
          React.createElement("br"),
          "चिकित्सा उपचार और सर्जिकल ऑपरेशन और / नैदानिक / चिकित्सीय प्रक्रियाओं के पूर्व-निर्माण के लिए प्राधिकरण।"
        )
      ),

      // Numbered Consent Points
      Array.from({ length: 11 }).map((_, index) => {
        if (index === 4) { // Position 5
          return React.createElement("div", { key: index, style: { marginBottom: "15px" , breakInside:"avoid", paddingTop:"70px" } },
            React.createElement("p", { style: { marginBottom: "10px" } }, "5. I state that I am having history of / मैं बताता/बताती हूं कि मुझे निम्नलिखित का इतिहास है:"),
            React.createElement("table", { 
              style: { 
                width: "100%", 
                borderCollapse: "collapse",
                marginTop: "10px"
              } 
            },
              React.createElement("tbody", null,
                [
                  ["(a) Heart disease/हृदय रोग", "(g) Jaundice/पीलिया"],
                  ["(b) Hypertension/उच्च रक्तचाप", "(h) Steroid therapy Past/Present/स्टेरॉयड थेरेपी पूर्व/वर्तमान"],
                  ["(c) Diabetes/मधुमेह", "(i) Alcohol consumption/शराब का सेवन"],
                  ["(d) Respiratory problem/सांस की समस्या", "(j) Known allergy/ज्ञात एलर्जी"],
                  ["(e) Bleeding disorder/रक्तस्राव विकार", "(k) Complication of Previous Anaesthesia/पिछली बेहोशी की समस्या"],
                  ["(f) Seizure disorder/दौरा", ""]
                ].map((row, index) => 
                  React.createElement("tr", { key: index },
                    row.map((cell, cellIndex) => 
                      React.createElement("td", { 
                        key: cellIndex,
                        style: { 
                          border: "1px solid black", 
                          padding: "12px",
                          width: "50%"
                        } 
                      },
                        React.createElement("div", { style: { display: "flex", justifyContent: "space-between", alignItems: "center" } },
                          React.createElement("span", null, cell),
                          cell && React.createElement("div", { 
                            style: { 
                              display: "flex",
                              gap: "10px",
                              alignItems: "center"
                            } 
                          },
                            React.createElement("span", null, "YES/हाँ"),
                            React.createElement("div", { 
                              style: { 
                                border: "1px solid black",
                                width: "25px",
                                height: "25px"
                              } 
                            }),
                            React.createElement("span", null, "NO/नहीं"),
                            React.createElement("div", { 
                              style: { 
                                border: "1px solid black",
                                width: "25px",
                                height: "25px"
                              } 
                            })
                          )
                        )
                      )
                    )
                  )
                )
              )
            )
          );
        } else {
          return React.createElement("div", { key: index, style: { marginBottom: "15px", breakInside:"avoid" } },
            React.createElement("p", {style:{breakInside:"avoid"}}, \`\${index + 1}. \${getConsentPoint(index + 1)}\`)
          );
        }
      }),
    ),

    // Certification Section
    React.createElement("div", { style: { marginBottom: "20px", breakInside:"avoid" ,paddingTop:"70px"} },
      React.createElement("p", { style: { textAlign: "justify" } },
        "I certify that I have received complete information and fully understood the above consent statement, that all of my questions have been answered to my satisfaction, that all blanks requiring insertion or completion were filled in, prior to the time of my signature, and that this consent is given with stable mind, freely, voluntarily and without reservation.",
        React.createElement("br"),
        "मैं प्रमाणित करता हूं कि मुझे पूरी जानकारी प्राप्त हो गई है और उपरोक्त सहमति कथन को पूरी तरह से समझ लिया गया है, कि मेरे सभी प्रश्नों का उत्तर मेरी संतुष्टि के लिए दिया गया है, कि सम्मिलन या पूर्णता की आवश्यकता वाले सभी रिक्त स्थान मेरे हस्ताक्षर के समय से पहले भरे गए थे, और यह सहमति स्थिर दिमाग से, स्वतंत्र रूप से, स्वेच्छा से दी गई है।"
      )
    ),

    // Authorization Section
    React.createElement("div", { style: { marginBottom: "20px" ,breakInside:"avoid"} },
      React.createElement("p", null, 
        "On the basis of the above statements (to be filled by the patient or the doctor to document in patient's own words)",
        React.createElement("br"),
        "उपरोक्त बयानों के आधार पर (रोगी या चिकित्सक द्वारा रोगी के अपने शब्दों में दस्तावेज करने के लिए भरा जाना)"
      ),
        React.createElement("p", null,
        \`I hereby authorize Dr. \${patient?.doctor?.name || "_________________"} and those designate as associates or assistants to perform upon me the following medical treatment, surgical operation and/ or diagnostic/ therapeutic procedure.\`,
        React.createElement("br"),
        \`मैं एतद्द्वारा डॉ. \${patient?.doctor?.name || "_________________"} और उन लोगों को जो सहयोगियों या सहायक के रूप में नामित हैं, मुझे निम्नलिखित चिकित्सा उपचार, शल्य चिकित्सा और/या निदान/चिकित्सीय प्रक्रिया करने के लिए अधिकृत करता हूं।\`
      )
    ),

    // Signature Section
    React.createElement("div", { style: { marginTop: "30px" } },
      // Patient and Representative Signature Table
      React.createElement("table", {
        style: {
          width: "100%",
          borderCollapse: "collapse",
          marginBottom: "0"
        }
      },
        React.createElement("thead", null,
          React.createElement("tr", null,
            React.createElement("th", { 
              style: { 
                border: "1px solid black", 
                padding: "3px",
                textAlign: "center",
                backgroundColor: "#f5f5f5"
              } 
            }, "Name of Patient/रोगी का नाम"),
            React.createElement("th", { 
              style: { 
                border: "1px solid black", 
                padding: "3px",
                textAlign: "center",
                backgroundColor: "#f5f5f5"
              } 
            }, "Signature/हस्ताक्षर"),
            React.createElement("th", { 
              style: { 
                border: "1px solid black", 
                padding: "3px",
                textAlign: "center",
                backgroundColor: "#f5f5f5"
              } 
            }, "Representative*(Relationship)/प्रतिनिधि*(संबंध)")
          )
        ),
        React.createElement("tbody", null,
          React.createElement("tr", null,
            React.createElement("td", { 
              style: { 
                border: "1px solid black", 
                padding: "5px",
                textAlign: "center"
              } 
            }, patient?.patient?.name || ""),
            React.createElement("td", { 
              style: { 
                border: "1px solid black", 
                padding: "5px",
                textAlign: "center"
              } 
            }, ""),
            React.createElement("td", { 
              style: { 
                border: "1px solid black", 
                padding: "5px",
                textAlign: "center"
              } 
            }, "")
          )
        )
      ),
      // Date, Time and Representative Signature Table
      React.createElement("table", {
        style: {
          width: "100%",
          borderCollapse: "collapse",
          marginTop: "-1px",
          marginBottom: "20px"
        }
      },
        React.createElement("thead", null,
          React.createElement("tr", null,
            React.createElement("th", { 
              style: { 
                border: "1px solid black", 
                padding: "3px",
                textAlign: "center",
                backgroundColor: "#f5f5f5"
              } 
            }, "Date/तारीख"),
            React.createElement("th", { 
              style: { 
                border: "1px solid black", 
                padding: "3px",
                textAlign: "center",
                backgroundColor: "#f5f5f5"
              } 
            }, "Time/समय"),
            React.createElement("th", { 
              style: { 
                border: "1px solid black", 
                padding: "3px",
                textAlign: "center",
                backgroundColor: "#f5f5f5"
              } 
            }, "Representative's Signature/प्रतिनिधि के हस्ताक्षर")
          )
        ),
        React.createElement("tbody", null,
          React.createElement("tr", null,
            React.createElement("td", { 
              style: { 
                border: "1px solid black", 
                padding: "5px",
                textAlign: "center"
              } 
            }, formatDate(patient?.bookingDate) || ""),
            React.createElement("td", { 
              style: { 
                border: "1px solid black", 
                padding: "5px",
                textAlign: "center"
              } 
            }, patient?.bookingTime || ""),
            React.createElement("td", { 
              style: { 
                border: "1px solid black", 
                padding: "5px",
                textAlign: "center"
              } 
            }, "")
          )
        )
      ),
      // Add note about representatives
      React.createElement("div", { 
        style: { 
          marginTop: "5px",
          fontSize: "12px",
          fontStyle: "italic",
          padding: "0 10px"
        } 
      },
        React.createElement("p", null,
          "*Representative of patient to sign for and on behalf of the patient if the patient is a minor, unconscious, otherwise lacks the ability to make informed decision.",
          React.createElement("br"),
          "*रोगी के प्रतिनिधि के लिए और रोगी की ओर से हस्ताक्षर करने के लिए यदि रोगी नाबालिग है, बेहोश है, अन्यथा सूचित निर्णय लेने की क्षमता का अभाव है।"
        )
      )
    ),

    // Doctor's Statement
    React.createElement("div", { style: { marginBottom: "20px" } },
      React.createElement("p", { style: { fontWeight: "bold", marginBottom: "15px" } }, "Doctor's Statement/डॉक्टर का बयान:"),
      React.createElement("p", { style: { textAlign: "justify", marginBottom: "20px" } },
        "I have explained the patient's condition, the procedure and risks, likely consequences if those risks occurs and the significant risks and problems specific to this patient. I have given the Patient/ Guardian an opportunity to ask questions about any of the above matters and raise any other concerns, which answered as fully as possible. I am of the opinion that the Patient/ Substitute decision maker understood the above information.",
        React.createElement("br"),
        "मैंने रोगी की स्थिति, प्रक्रिया और जोखिमों, संभावित परिणामों की व्याख्या की है यदि वे जोखिम होते हैं और इस रोगी के लिए विशिष्ट महत्वपूर्ण जोखिम और समस्याएं। मैंने रोगी/अभिभावक को उपरोक्त किसी भी मामले के बारे में प्रश्न पूछने और किसी भी अन्य चिंताओं को उठाने का अवसर दिया है, जिसका उत्तर यथासंभव पूरी तरह से दिया गया है। मेरी राय है कि रोगी/स्थानापन्न निर्णय निर्माता ने उपरोक्त जानकारी को समझा।"
      ),
      // Doctor's Details Table
      React.createElement("table", {
        style: {
          width: "100%",
          borderCollapse: "collapse",
          marginBottom: "0"
        }
      },
        React.createElement("thead", null,
          React.createElement("tr", null,
            React.createElement("th", { 
              style: { 
                border: "1px solid black", 
                padding: "3px",
                textAlign: "center",
                backgroundColor: "#f5f5f5"
              } 
            }, "Name of Doctor/डॉक्टर का नाम"),
            React.createElement("th", { 
              style: { 
                border: "1px solid black", 
                padding: "3px",
                textAlign: "center",
                backgroundColor: "#f5f5f5"
              } 
            }, "Designation/पद"),
            React.createElement("th", { 
              style: { 
                border: "1px solid black", 
                padding: "3px",
                textAlign: "center",
                backgroundColor: "#f5f5f5"
              } 
            }, "Signature/हस्ताक्षर")
          )
        ),
        React.createElement("tbody", null,
          React.createElement("tr", null,
            React.createElement("td", { 
              style: { 
                border: "1px solid black", 
                padding: "5px",
                textAlign: "center"
              } 
            }, patient?.doctor?.name || ""),
            React.createElement("td", { 
              style: { 
                border: "1px solid black", 
                padding: "5px",
                textAlign: "center"
              } 
            }, ""),
            React.createElement("td", { 
              style: { 
                border: "1px solid black", 
                padding: "15px",
                textAlign: "center"
              } 
            }, "")
          )
        )
      ),
      // Doctor's Date and Time Table
      React.createElement("table", {
        style: {
          width: "100%",
          borderCollapse: "collapse",
          marginTop: "-1px"
        }
      },
        React.createElement("thead", null,
          React.createElement("tr", null,
            React.createElement("th", { 
              style: { 
                border: "1px solid black", 
                padding: "3px",
                textAlign: "center",
                backgroundColor: "#f5f5f5"
              } 
            }, "Date/तारीख"),
            React.createElement("th", { 
              style: { 
                border: "1px solid black", 
                padding: "3px",
                textAlign: "center",
                backgroundColor: "#f5f5f5"
              } 
            }, "Time/समय")
          )
        ),
        React.createElement("tbody", null,
          React.createElement("tr", null,
            React.createElement("td", { 
              style: { 
                border: "1px solid black", 
                padding: "5px",
                textAlign: "center"
              } 
            }, formatDate(patient?.bookingDate) || ""),
            React.createElement("td", { 
              style: { 
                border: "1px solid black", 
                padding: "5px",
                textAlign: "center"
              } 
            }, patient?.bookingTime || "")
          )
        )
      )
    ),

    // High Risk Consent
    React.createElement("div", { style: {  paddingTop: "70px" } },
    React.createElement("div", { style: {  border: "1px solid black", padding: "15px", breakInside:"avoid" } },
      React.createElement("h3", { style: { textAlign: "center", marginBottom: "15px" } }, 
        "HIGH RISK CONSENT/ उच्च जोखिम सहमति"
      ),
      React.createElement("p", { style: { textAlign: "justify" } },
        "It has been explained to me and I have acknowledged, that the risk to the life of patient, in my case during and after the surgery is high/ very high due to following problems and their implication to surgical stress.",
        React.createElement("br"),
        "मुझे यह समझाया गया है और मैंने स्वीकार किया है कि सर्जरी के दौरान और बाद में रोगी के जीवन के लिए जोखिम निम्नलिखित समस्याओं और सर्जिकल तनाव के कारण उनके निहितार्थ के कारण उच्च/बहुत अधिक है।"
      ),
      React.createElement("div", { style: { margin: "15px 0" } },
        React.createElement("p", null, "1. _______________________"),
        React.createElement("p", null, "2. _______________________"),
        React.createElement("p", null, "3. _______________________"),
        React.createElement("p", null, "4. _______________________")
      ),
      React.createElement("div", { style: { marginTop: "30px", display: "flex", justifyContent: "space-between" } },
        React.createElement("div", null,
          React.createElement("p", null, "Signature of Doctor/डॉक्टर के हस्ताक्षर"),
          React.createElement("p", null, "Date/तारीख")
        ),
        React.createElement("div", null,
          React.createElement("p", null, "Signature/Thumb impression of patient/relative"),
          React.createElement("p", null, "रोगी/रिश्तेदार के हस्ताक्षर/अंगूठे का निशान")
        )
      )
)),

    // Witness Section
    React.createElement("div", { style: { marginTop: "20px" } },
      React.createElement("p", null, "Witness/गवाह"),
      React.createElement("p", null, "Name/नाम: _________________"),
      React.createElement("p", null, "Signature/हस्ताक्षर: _________________")
      )
    );
  }`;

  export const consentFormTemplateStringAnesthesia = `
  (patient, hospitalInfo, ref) => {
      // Helper function to format dates (DD-MMM-YYYY) using string concatenation
      const formatDate = (dateString) => {
        if (!dateString) return "......................";
        try {
          const date = new Date(dateString);
          if (isNaN(date.getTime())) {
            return "......................";
          }
          const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
          return date.getDate().toString().padStart(2, '0') + '-' + months[date.getMonth()] + '-' + date.getFullYear();
        } catch (e) {
          console.error("Error formatting date:", e);
          return "......................";
        }
      };
  
      // Helper function to format time (HH:MM AM/PM) using string concatenation
      const formatTime = (dateString) => {
          if (!dateString) return "......................";
          try {
              const date = new Date(dateString);
              if (isNaN(date.getTime())) {
                  return "......................";
              }
              let hours = date.getHours();
              const minutes = date.getMinutes().toString().padStart(2, '0');
              const ampm = hours >= 12 ? 'PM' : 'AM';
              hours = hours % 12;
              hours = hours ? hours : 12; // the hour '0' should be '12'
              return hours.toString().padStart(2, '0') + ':' + minutes + ' ' + ampm;
          } catch (e) {
              console.error("Error formatting time:", e);
              return "......................";
          }
      };
  
      // Helper function to create a visual checkbox field (non-interactive for print)
      // Updated to allow passing custom styles
      const createCheckboxField = (label, checked = false, customStyles = {}) => {
        const baseStyle = { display: "flex", alignItems: "center", gap: "5px", margin: "2px 0", lineHeight: "1.1" };
        const labelStyle = { flex: 1, fontSize: "10px", ...customStyles.label }; // Apply custom label styles
        const checkboxStyle = { border: "1px solid black", width: "16px", height: "16px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: checked ? "#eee" : "white", ...customStyles.box }; // Apply custom box styles
  
        return React.createElement("div", { style: { ...baseStyle, ...customStyles.container } }, // Apply custom container styles
          React.createElement("div", { style: checkboxStyle }, checked ? "✓" : ""),
          React.createElement("span", { style: labelStyle }, label)
        );
      };
  
  
      // Helper function to create sections for each anesthesia type in the new table format
      const createAnesthesiaTypeSection = (type, index) => {
        const tableStyles = {
           outerTable: { width: "100%", borderCollapse: "collapse", border: "1px solid black" },
           td: { border: "1px solid black", padding: "5px", verticalAlign: "top" },
           titleCell: { width: "25%" },
           labelCell: { width: "20%", fontWeight: "bold" },
           dataCell: { width: "55%", textAlign: "justify" },
           checkboxContainer: { marginTop: "5px", paddingLeft: "10px" }
        };
  
        return React.createElement("div", { style: { paddingTop: index === 4 ? "70px" : "0px", breakInside: "avoid" } },
        React.createElement("table", { key: type.title, style: { ...tableStyles.outerTable  } },
          React.createElement("tbody", null,
             React.createElement("tr", null,
                 React.createElement("td", { style: { ...tableStyles.td, ...tableStyles.titleCell }, rowSpan: 3 },
                     React.createElement("h4", { style: { fontWeight: "bold", marginBottom: "5px", fontSize: "12px" } }, type.title),
                     type.withSedation && React.createElement("div", { style: tableStyles.checkboxContainer },
                       createCheckboxField("With sedation / बेहोशी की दवा के साथ", type.sedation === 'with'),
                       createCheckboxField("Without sedation / बिना बेहोश किए", type.sedation === 'without')
                     )
                 ),
                 React.createElement("td", { style: { ...tableStyles.td, ...tableStyles.labelCell } }, type.title.includes("Cannulation") ? "Indication / संकेत:" : "Expected Result / अपेक्षित परिणाम:"), // Adjust label for CVC/Art line
                 React.createElement("td", { style: { ...tableStyles.td, ...tableStyles.dataCell } }, type.expectedResult)
             ),
             React.createElement("tr", null,
                 React.createElement("td", { style: { ...tableStyles.td, ...tableStyles.labelCell } }, "Technique / तकनीक:"),
                 React.createElement("td", { style: { ...tableStyles.td, ...tableStyles.dataCell } }, type.technique)
             ),
             React.createElement("tr", null,
                 React.createElement("td", { style: { ...tableStyles.td, ...tableStyles.labelCell } }, "Risks (Include, but are not limited to) / जोखिम (...):"),
                 React.createElement("td", { style: { ...tableStyles.td, ...tableStyles.dataCell } }, type.risks)
             )
          )
          )
        );
      };
  
      // Define the data for each anesthesia type
      const anesthesiaTypes = [
         {
              title: "1. General Anesthesia / TIVA (Total Intravenous Anesthesia) / जनरल एनेस्थीसिया / टीआईवीए (टोटल इंट्रावेनस एनेस्थीसिया)",
              withSedation: false,
              expectedResult: "Total unconscious state, possible placement of a tube into the windpipe. / पूर्ण अचेतन अवस्था, श्वासनली में एक नली का संभावित स्थान।",
              technique: "Drugs injected into the bloodstream or breathed into the lungs. / ड्रग्स को रक्तप्रवाह में इंजेक्ट किया जाता है या फेफड़ों में सांस लिया जाता है।",
              risks: "Mouth or throat pain, hoarseness, injury to mouth or teeth or dental work, eye abrasions, awareness under anesthesia, injury to blood vessels, vomit in the lungs, pneumonia, visual loss following surgery in prone position, inability to place the breathing tube. / मुंह या गले में दर्द, स्वर बैठना, मुंह या दांतों या दंत चिकित्सा के काम में चोट, आंखों का घर्षण, संज्ञाहरण के तहत जागरूकता, रक्त वाहिकाओं को चोट, फेफड़ों में उल्टी, निमोनिया, प्रवण स्थिति में सर्जरी के बाद दृश्य हानि, श्वास नली लगाने में असमर्थता।"
            },
            {
              title: "2. Spinal / Epidural Analgesia / Anesthesia / स्पाइनल/एपिड्यूरल एनाल्जेसिया / एनेस्थीसिया",
              withSedation: true,
              sedation: patient?.anesthesiaOptions?.spinalEpiduralSedation,
              expectedResult: "Temporary / Long term decreased or loss of feeling and / or movement to lower part of the body. Occasionally the surgery / procedure's intended / expected results may not be achieved, one may have to switch over to another alternative. / अस्थायी / दीर्घकालिक कमी या शरीर के निचले हिस्से में भावना और / या आंदोलन का नुकसान। कभी-कभी सर्जरी / प्रक्रिया के इच्छित / अपेक्षित परिणाम प्राप्त नहीं किए जा सकते हैं, किसी को दूसरे विकल्प पर स्विच करना पड़ सकता है।",
              technique: "Drug injected through a needle / catheter placed either directly into the spinal canal or immediately outside the spinal canal. / दवा को सुई / कैथेटर के माध्यम से इंजेक्ट किया जाता है जिसे या तो सीधे रीढ़ की हड्डी की नहर में या रीढ़ की हड्डी की नहर के बाहर रखा जाता है।",
              risks: "Headache, backache, buzzing in the ears, convulsions, infection, nerve damage leading to persistent weakness, difficulty in passing urine, numbness, residual pain, injury to blood vessels, hematoma. / सिरदर्द, पीठ दर्द, कानों में भनभनाहट, ऐंठन, संक्रमण, तंत्रिका क्षति के कारण लगातार कमजोरी, मूत्र पारित करने में कठिनाई, स्तब्ध हो जाना, अवशिष्ट दर्द, रक्त वाहिकाओं में चोट, रक्तगुल्म।"
            },
            {
              title: "3. Nerve Block / तंत्रिका ब्लॉक",
              withSedation: true,
              sedation: patient?.anesthesiaOptions?.nerveBlockSedation,
              expectedResult: "Temporary / Long term loss of feeling, and / or movement of specific limb. Occasionally the surgery / procedure's intended / expected results may not be achieved; one may have to switch over to the other alternative. / भावना का अस्थायी / दीर्घकालिक नुकसान, और / या विशिष्ट अंग का आंदोलन। कभी-कभी सर्जरी / प्रक्रिया के इच्छित / अपेक्षित परिणाम प्राप्त नहीं किए जा सकते हैं; एक को दूसरे विकल्प पर स्विच करना पड़ सकता है।",
              technique: "Drug injected through a needle / catheter placed near the nerves supplying the area of surgery. / दवा को सर्जरी के क्षेत्र की आपूर्ति करने वाली नसों के पास रखी सुई / कैथेटर के माध्यम से इंजेक्ट किया जाता है।",
              risks: "Infection, convulsions, weakness, persistent numbness, residual pain, injury to blood vessels, nerve damage. / संक्रमण, आक्षेप, कमजोरी, लगातार सुन्नता, अवशिष्ट दर्द, रक्त वाहिकाओं में चोट, तंत्रिका क्षति।"
            },
            {
              title: "4. I/V Regional Anesthesia / अंतःशिरा क्षेत्रीय संज्ञाहरण",
              withSedation: true,
              sedation: patient?.anesthesiaOptions?.ivRegionalSedation,
              expectedResult: "Temporary / Long term loss of feeling, and / or movement of limb. Occasionally the surgery/ procedure's intended / expected results may not be achieved; one may have to switch over to the other alternative. / अस्थायी / दीर्घकालिक हानि की भावना, और / या अंग की गति। कभी-कभी सर्जरी/प्रक्रिया के अभी/अपेक्षित परिणाम प्राप्त नहीं हो सकते हैं; एक को दूसरे विकल्प पर स्विच करना पड़ सकता है।",
              technique: "Drug injected into the veins of arm or leg while using a tourniquet. / टूर्निकेट का उपयोग करते समय हाथ या पैर की नसों में इंजेक्ट की जाने वाली दवा।",
              risks: "Infection, convulsions, weakness, persistent numbness, residual pain, injury to blood vessels, tourniquet pain or nerve injury. / संक्रमण, आक्षेप, कमजोरी, लगातार सुन्नता, अवशिष्ट दर्द, रक्त वाहिकाओं में चोट, टूर्निकेट दर्द या तंत्रिका चोट।"
            },
            {
              title: "5. Monitored Anesthesia Care (With Sedation) / निगरानी की गई एनेस्थीसिया देखभाल (सेडेशन के साथ)",
              withSedation: false,
              expectedResult: "Reduced anxiety and pain, partial or total amnesia. / कम चिंता और दर्द, आंशिक या कुल भूलने की बीमारी।",
              technique: "Drug injected into the bloodstream, breathed into the lungs, or by other routes producing a semi-conscious state. / दवा को रक्तप्रवाह में इंजेक्ट किया जाता है, फेफड़ों में सांस ली जाती है, या अन्य मार्गों से अर्ध-सचेत अवस्था पैदा होती है।",
              risks: "An unconscious state, depressed breathing, injury to blood vessels, need to convert to general anesthesia. / एक बेहोशी की अवस्था, उदास श्वास, रक्त वाहिकाओं में चोट, सामान्य संज्ञाहरण में बदलने की आवश्यकता।"
            },
            {
              title: "6. Monitored Anesthesia Care (Without Sedation) / निगरानी की गई एनेस्थीसिया देखभाल (बेहोश करने की क्रिया के बिना)",
              withSedation: false,
              expectedResult: "Measurement of vital signs, availability of anesthesia provider for further intervention. / महत्वपूर्ण संकेतों का मापन, आगे के हस्तक्षेप के लिए संज्ञाहरण प्रदाता की उपलब्धता।",
              technique: "None / कोई नहीं",
              risks: "Increased awareness, anxiety and / or discomfort. Possibility of needing sedation or other anesthesia type. / जागरूकता, चिंता और / या असुविधा में वृद्धि। बेहोश करने की क्रिया या अन्य संज्ञाहरण प्रकार की आवश्यकता की संभावना।"
            },
            {
              title: "7. Central Venous Cannulation / सेंट्रल वेनस कैन्युलेशन",
              withSedation: false,
              expectedResult: "Continuous measurement of volume status, delivery of medication infusions, parenteral nutrition. / नियमित रूप से आवृत्ति स्थिति का मापन, दवा इन्फ्यूजन की पहुँच, पैरेंटेरल पोषण।", // Using original indication text
              technique: "Placement of a catheter into a large central vein (e.g., subclavian, internal jugular, femoral). / एक बड़े केंद्रीय शिरा (जैसे, सबक्लेवियन, आंतरिक जुगुलर, ऊरु) में कैथेटर का स्थानन।",
              risks: "Bleeding, formation of clot, injury to lung and /or pleura (pneumothorax), bleeding in the lungs, injury to blood vessels, infection, air embolism, swelling and blockage of blood vessels, arrhythmias. / रक्तस्राव, थक्का बनना, फेफड़े और/या फुफ्फुस (न्यूमोथोरैक्स) में चोट, फेफड़ों में रक्तस्राव, रक्त वाहिकाओं में चोट, संक्रमण, वायु अन्त embolism, रक्त वाहिकाओं में सूजन और रुकावट, अतालता।"
            },
            {
              title: "8. Arterial Cannulation / धमनीय कैन्युलेशन",
              withSedation: false,
              expectedResult: "Continuous measurement of pulse, blood pressure, oxygen saturation, acidosis (via blood gas sampling). / नाड़ी, रक्तचाप, ऑक्सीजन संतृप्ति, एसिडोसिस (रक्त गैस नमूनाकरण के माध्यम से) का निरंतर माप।", // Using original indication text
              technique: "Placement of a catheter into an artery (e.g., radial, femoral, brachial). / धमनी (जैसे, रेडियल, ऊरु, बाहु) में कैथेटर का स्थानन।",
              risks: "Bleeding, formation of clot, injury to blood vessels, swelling and blockage of blood vessels, nerve damage, infection, temporary or permanent loss of circulation to extremity. / रक्तस्राव, थक्का बनना, रक्त वाहिकाओं में चोट, रक्त वाहिकाओं की सूजन और रुकावट, तंत्रिका क्षति, संक्रमण, छोर तक परिसंचरण का अस्थायी या स्थायी नुकसान।"
            }
      ];
  
      // Style definitions
      const styles = {
        page: { padding: "20px", fontSize: "11px", lineHeight: "1.4", fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif", color: "#333", border: "1px solid #ccc", margin: "10px auto", maxWidth: "800px", backgroundColor: "#fff" },
        header: { textAlign: "center", marginBottom: "20px", borderBottom: "2px solid black", paddingBottom: "10px" },
        headerH1: { margin: "0", fontSize: "18px", fontWeight: "bold" },
        headerH2: { margin: "5px 0 0 0", fontSize: "16px", fontWeight: "bold" },
        sectionTitle: { fontWeight: "bold", marginTop: "15px", marginBottom: "5px", fontSize: "13px", textDecoration: "underline" },
        table: { width: "100%", borderCollapse: "collapse", marginBottom: "15px" },
        th: { border: "1px solid black", padding: "5px", textAlign: "center", fontWeight: "bold", backgroundColor: "#f2f2f2" },
        td: { border: "1px solid black", padding: "5px", textAlign: "left", verticalAlign: "top" },
        listItem: { marginBottom: "8px", textAlign: "justify" },
        signatureBox: { borderBottom: "1px solid black", height: "30px", marginBottom: "5px" },
        signatureLabel: { fontSize: "10px", textAlign: "center" },
        twoCol: { display: "flex", justifyContent: "space-between", gap: "20px", marginBottom: "10px" },
        twoColItem: { flexBasis: "48%" },
        patientInfoTable: { width: "100%", marginBottom: "15px", border: "none" },
        patientInfoTdLabel: { fontWeight: "bold", padding: "2px 5px", width: "auto", border: "none" },
        patientInfoTdValue: { borderBottom: "1px dotted #666", padding: "2px 5px", width: "auto", borderTop: "none", borderLeft: "none", borderRight: "none" },
        hindiText: { /* Optional: fontFamily: "'Noto Sans Devanagari', sans-serif" */ },
        riskTableTd: { border: "1px solid black", padding: "5px", textAlign: "left", verticalAlign: "top", fontSize: "10px" },
        placeholderLine: { borderBottom: "1px dotted #666", display: "inline-block", minWidth: "150px", marginLeft: "5px", marginRight: "5px" },
        // Styles for Risk Stratification
        riskStratContainer: { marginTop: "20px", borderTop: "1px dashed #ccc", paddingTop: "10px"},
        riskStratTitle: { fontWeight: "bold", fontSize: "13px", marginBottom: "2px" },
        riskStratSubtitle: { fontSize: "10px", fontStyle: "italic", marginBottom: "10px" },
        riskStratCheckboxes: { display: "flex", justifyContent: "space-around", alignItems: "center" },
         riskStratSignature: { marginTop: "5px", textAlign: "right" } // Align signature to the right
      };
  
      // Main component structure using React.createElement
      return React.createElement("div", { ref: ref, className: "print-content", style: styles.page },
        // Hospital Header
        React.createElement(HospitalHeader, { hospitalInfo: hospitalInfo }),
  
        // Title
        React.createElement("div", { style: { textAlign: "center", margin: "15px 0" } },
          React.createElement("h2", { style: styles.headerH2 }, "INFORMED CONSENT FOR ANESTHESIA"),
          React.createElement("h2", { style: { ...styles.headerH2, ...styles.hindiText } }, "संज्ञाहरण/एनेस्थिसिया के लिए सूचित सहमति")
        ),
  
        // Patient Details Table
         React.createElement("table", { style: styles.patientInfoTable },
              React.createElement("tbody", null,
                  React.createElement("tr", null,
                      React.createElement("td", { style: styles.patientInfoTdLabel }, "Name/नाम:"),
                      React.createElement("td", { style: styles.patientInfoTdValue, colSpan: 3 }, patient?.patient?.name || ""),
                      React.createElement("td", { style: styles.patientInfoTdLabel }, "Reg. No:/पंजीकरण संख्या:"),
                      React.createElement("td", { style: styles.patientInfoTdValue }, patient?.registrationNumber || "")
                  ),
                  React.createElement("tr", null,
                      React.createElement("td", { style: styles.patientInfoTdLabel }, "Age/आयु:"),
                      React.createElement("td", { style: styles.patientInfoTdValue }, patient?.patient?.age || ""),
                      React.createElement("td", { style: styles.patientInfoTdLabel }, "Gender/लिंग:"),
                      React.createElement("td", { style: styles.patientInfoTdValue }, patient?.patient?.gender || ""),
                      React.createElement("td", { style: styles.patientInfoTdLabel }, "Date/तारीख:"),
                      React.createElement("td", { style: styles.patientInfoTdValue }, formatDate(patient?.consentDate || new Date()))
                  )
              )
          ),
  
        // Initial Patient/Doctor Declaration Section
          React.createElement("div", { style: { marginBottom: "15px", textAlign: "justify" } },
            React.createElement("p", null, "TO BE FILLED BY THE PATIENT OR THE DOCTOR TO DOCUMENT IN PATIENT'S OWN WORDS / पेशेंट या डॉक्टर द्वारा भरने के लिए, मरीज की खुद की बोली में दस्तावेज़ करने के लिए"),
            React.createElement("p", null,
              "I ", React.createElement("span", { style: styles.placeholderLine }, patient?.patient?.name || ""),
              " have been scheduled for following surgery/procedure ", React.createElement("span", { style: styles.placeholderLine }, patient?.operationName || ""),
              " under Dr. ", React.createElement("span", { style: styles.placeholderLine }, patient?.doctor?.name || ""),
              " Anesthesia services are needed so that my doctor can perform the operation or surgery/ Procedure.",
              React.createElement("br"),
               React.createElement("span", { style: styles.hindiText },
              "मैं ", React.createElement("span", { style: styles.placeholderLine }, patient?.patient?.name || ""),
               " डॉक्टर ", React.createElement("span", { style: styles.placeholderLine }, patient?.doctor?.name || ""),
               " द्वारा नीचे बताई गई सर्जरी/प्रोसीजर ", React.createElement("span", { style: styles.placeholderLine }, patient?.operationName || ""),
               " होनी है। मैं स्वीकार करता/ करती हूं कि इसके लिए एनेस्थिसिया सेवा की जरूरत है ताकि मेरे डॉक्टर ऑपरेशन या सर्जरी/प्रोसीजर को कर सकें।"
               )
            )
          ),
  
        // Anesthesiologist Awareness Declaration
          React.createElement("div", { style: { margin: "10px 0", textAlign: "justify" } },
            React.createElement("p", null,
              "I am aware that anesthesia will be provided to me/patient by a trained and skilled anesthesiologist, who will be monitoring my health throughout the surgery/procedure and whose goal will be to ensure a safe and comfortable surgery for me. It has been explained to me, however, that all forms of anesthesia involve some risks, even at the hands of competent and experienced anesthesiologists.",
              React.createElement("br"),
              React.createElement("span", { style: styles.hindiText }, "मैं जानता /जानती हूं कि मुझे/मरीज को एनेस्थिसिया एक प्रशिक्षित और योग्य एनेस्थिसियो लॉजिस्ट द्वारा दिया जाएगा, जो पूरी सर्जरी/प्रोसीजर(प्रक्रिया) के दौरान मेरे स्वास्थ्य पर नजर रखेगा और जिसका उद्देश्य मेरे लिए सुरक्षित और सुगम/आसान/आरामदायक सर्जरी सुनिश्चित करना होगा। हालांकि मुझे यह बताया गया है कि सभी प्रकार के एनेस्थिसिया में कुछ जोखिम होते हैं, यहां तक कि सक्षम और अनुभवी एनेस्थिसियोलॉजिस्ट द्वारा दिए जाने वाले एनेस्थिसिया में भी ये जोखिम होते हैं।")
            )
          ),
  
        // Risk Categories Table
          React.createElement("div", { style: { margin: "15px 0" } },
            React.createElement("table", { style: styles.table },
              React.createElement("thead", null,
                React.createElement("tr", null,
                  React.createElement("th", { style: styles.th }, "Common risks / सामान्य जोखिम"),
                  React.createElement("th", { style: styles.th }, "Uncommon risks / असामान्य जोखिम"),
                  React.createElement("th", { style: styles.th }, "Extremely Rare Risks / अत्यंत दुर्लभ जोखिम")
                )
              ),
              React.createElement("tbody", null,
                 React.createElement("tr", null,
                  React.createElement("td", { style: styles.riskTableTd }, "Bruising at the site of injection / Drip / Contusion / Itching / Sore throat / इंजेक्शन की जगह पर चोट लगना / टपकना / चोट लगना / खुजली / गले मे खराश"),
                  React.createElement("td", { style: styles.riskTableTd }, "Infection in chest / छाती में संक्रमण"),
                  React.createElement("td", { style: styles.riskTableTd }, "Drug allergies including severe life-threatening reactions / दवा से होने वाली एलर्जी / सर्वर जीवन-घातक प्रतिक्रियाएँ")
                ),
                React.createElement("tr", null,
                  React.createElement("td", { style: styles.riskTableTd }, "Nausea & Vomiting, dizziness, blurred vision, damage to lips, tongue (minor) / मतली और उल्टी, चक्कर आना, धुंधली दृष्टि, होंठ, जीभ को नुकसान (नाबालिग)"),
                   React.createElement("td", { style: styles.riskTableTd }, "Bleeding, damage to teeth / रक्तस्राव, दांतों को नुकसान"),
                  React.createElement("td", { style: styles.riskTableTd }, "Blood clot or air lock in the leg / heart / lungs / brain / पैर/हृदय/फेफड़े/मस्तिष्क में रक्त का थक्का या वायु अवरोध")
                ),
                 React.createElement("tr", null,
                   React.createElement("td", { style: styles.riskTableTd }, "Temporary muscle pains / अस्थायी मांसपेशियों में दर्द"),
                   React.createElement("td", { style: styles.riskTableTd }, "Wheezing and difficulty in breathing, awareness, existing medical condition getting worse / घरघराहट और सांस लेने में कठिनाई, जागरूकता, मौजूदा चिकित्सा स्थिति बदतर होती जा रही है"),
                    React.createElement("td", { style: styles.riskTableTd }, "Heart attack, damage to eye / दिल का दौरा, आंख को नुकसान")
                 ),
                React.createElement("tr", null,
                  React.createElement("td", { style: styles.riskTableTd }, "" ),
                   React.createElement("td", { style: styles.riskTableTd }, "Burns following use of cautery / laser, nerve damage / दाग़ने/लेज़र के उपयोग के बाद जलन, तंत्रिका क्षति"),
                  React.createElement("td", { style: styles.riskTableTd }, "Death, equipment failure / मृत्यु, उपकरण विफलता")
                )
              )
            ),
             React.createElement("p", { style: { fontSize: "10px", fontStyle: "italic"} }, "Risks are increased by / जोखिम बढ़ जाते हैं: Diabetes, heart disease, high blood pressure, Cold or Flu, Asthma or other chest diseases. Obesity, Kidney diseases. Other severe diseases / मधुमेह, हृदय रोग, उच्च रक्तचाप सर्दी या फ्लू, अस्थमा या अन्य छाती के रोग। मोटापा, गुर्दे की बीमारियाँ। अन्य गंभीर बीमारियाँ")
          ),
  
  
        // Understanding Risks Statement
           React.createElement("div", { style: { margin: "10px 0", textAlign: "justify" } },
               React.createElement("p", null,
                   "I understood that these risks apply to all forms of anesthesia; additional risks apply to specific techniques. / मैं समझ गया कि ये जोखिम सभी प्रकार के एनेस्थीसिया पर लागू होते हैं; अतिरिक्त जोखिम विशिष्ट पर लागू होते हैं।"
               ),
                React.createElement("p", null,
                   "I understand that the type(s) of anesthesia checked below will be used for my surgery/procedure and that the anesthesia technique to be used is determined by many factors including my physical condition, the type of surgery/procedure, my doctor's preference as well as my own desire. / मैं समझता हूं कि नीचे जांचे गए एनेस्थीसिया के प्रकार मेरी सर्जरी/प्रक्रिया के लिए किया जाएगा और उपयोग की जाने वाली एनेस्थीसिया तकनीक मेरी शारीरिक स्थिति, सर्जरी/प्रक्रिया के प्रकार, मेरे डॉक्टर की पसंद सहित मेरी इच्छा के आधार पर किया जाएगा।"
               ),
               React.createElement("p", null,
                   "It has been explained to me that sometimes an anesthesia technique which involves the use of local anesthetics, with or without sedation, may not succeed completely and therefore another technique may have to be used including general anesthesia. / मुझे बता दिया गया है कि अगर एनेस्थिसिया तकनीक, जिसमें बेहोश करके या बिना बेहोश किए लोकल एनेस्थिसिया दिया जाता है, अगर ये पूरी तरह से सफल नहीं रहता है तो जनरल एनेस्थिसिया सहित दूसरी तकनीकों का इस्तेमाल किया जा सकता है।"
               ),
                React.createElement("p", null,
                   "Proposed treatment option and their complications: I understand that these complications have been listed to me to help me make an informed decision, and that the possibility of a major complication is remote. / प्रस्तावित उपचार विकल्प और उनकी जटिलताएंः मैं, जानता/ जानती हूं कि इन जटिलताओं को मुझे एक सूचित निर्णय लेने में मदद करने के लिए सूचीबद्ध किया गया है और यह भी कि प्रमुख जटिलताओं की संभावना कम है।"
               )
           ),
  
        // Anesthesia Types Section
        React.createElement("div", { style: { margin: "15px 0" , breakBefore: "page", paddingTop: "70px"} },
          React.createElement("h3", {
            style: { fontWeight: "bold", marginTop: "15px", marginBottom: "10px", fontSize: "13px" }
          }, "Please (✓) Tick whichever is applicable and (×) across the not applicable / कृपया सही (✓) का निशान लगाएं जहां भी लागू हो और क्रॉस (×) का निशान जहां लागू न हो"),
          anesthesiaTypes.map((type, index) => createAnesthesiaTypeSection(type, index))
        ),
  
        // Patient Specific Risks Section
        React.createElement("div", { style: { margin: "15px 0" , } },
          React.createElement("h4", { style: styles.sectionTitle }, "Patient Specific risks / रोगी विशिष्ट जोखिम:"),
          React.createElement("div", { style: { display:"grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "5px"} },
          React.createElement("p", null, "1) ", React.createElement("span", { style: styles.placeholderLine }, (patient?.specificRisks && patient.specificRisks[0]) || "")),
          React.createElement("p", null, "2) ", React.createElement("span", { style: styles.placeholderLine }, (patient?.specificRisks && patient.specificRisks[1]) || "")),
          React.createElement("p", null, "3) ", React.createElement("span", { style: styles.placeholderLine }, (patient?.specificRisks && patient.specificRisks[2]) || "")),
          React.createElement("p", null, "4) ", React.createElement("span", { style: styles.placeholderLine }, (patient?.specificRisks && patient.specificRisks[3]) || "")),
          React.createElement("p", null, "5) ", React.createElement("span", { style: styles.placeholderLine }, (patient?.specificRisks && patient.specificRisks[4]) || "")),
          React.createElement("p", null, "6) ", React.createElement("span", { style: styles.placeholderLine }, (patient?.specificRisks && patient.specificRisks[5]) || ""))
          )
        ),
  
        // Declaration by Patient/Representative Section
        React.createElement("div", { style: { margin: "15px 0" } },
          React.createElement("h4", { style: styles.sectionTitle }, "DECLARATION BY PATIENT/ SPOUSE / CLOSE RELATIVE OF PATIENT / मरीज/पति/पत्नी/निकट संबंधी द्वारा घोषणा"),
          React.createElement("p", { style: { fontStyle: "italic", fontSize: "10px"} }, "(If patient is unable to make an informed consent / अगर मरीज सूचित सहमति देने में असमर्थ है)"),
          React.createElement("ul", { style: { listStyleType: "'• '", paddingLeft: "20px", marginTop: "5px" } },
            React.createElement("li", { style: styles.listItem },
                "I acknowledge the anesthesiologist has fully informed me about the anesthetic plan and the alternatives available. All my specific queries and concerns about this matter have been addressed satisfactorily. / ",
                React.createElement("span", { style: styles.hindiText }, "मैं, स्वीकार करती/करता हूं कि एनेस्थिसियोलॉजिस्ट ने मुझे एनेस्थिटिक योजना और उपलब्ध विकल्पों के बारे में बता दिया है। इस संबंध में मेरे सभी सवालों और आशंकाओं का समाधान संतोषजनक ढंग से कर दिया गया है।")
            ),
            React.createElement("li", { style: styles.listItem },
                "I acknowledge that I have discussed with the anesthesiologist significant risks and complications specific to my / the patient's individual circumstances. / ",
                 React.createElement("span", { style: styles.hindiText }, "मैं, स्वीकार करती/करता हूं कि मेरे/मरीज की परिस्थति से संबंधित महत्वपूर्ण जोखिमों और जटिलताओं के बारे में मैंने एनेस्थिसियोलॉजिस्ट से चर्चा कर ली है।")
            ),
            React.createElement("li", { style: styles.listItem },
                "I consent to the anesthesia service(s) and other eventualities discussed above and authorize that it be administered to me/my ward by an anesthesia care team, under the supervision of a consultant/ senior consultant in anesthesia. I also consent to any alternative form of anesthesia, if necessary, as deemed appropriate by the anesthesia care team. / ",
                 React.createElement("span", { style: styles.hindiText }, "मैं, उपर्युक्त एनेस्थिसिया सेवा(ओं) और अन्य संभावित परिणामों पर की गयी चर्चा के बाद इसके लिए सहमति देती/देता हूं और अधिकृत करती/करता हूं कि एनेस्थिसिया के डॉक्टर/वरिष्ट डॉक्टर की निगरानी में एनेस्थिसिया केयर टीम द्वारा यह मेरे मरीज को दिया जाए। मैं एनेस्थिसिया केयर टीम द्वारा यथोचित और यथा आवश्यक एनेस्थिसिया के किसी दूसरे विकल्प के लिए भी सहमति देती/देता हूं।")
            )
          )
        ),
  
        // *** NEW: Risk Stratification Section ***
         React.createElement("div", { style: { ...styles.riskStratContainer, breakBefore: "page", breakInside: "avoid" } },
             React.createElement("h4", { style: styles.riskStratTitle }, "Risk Stratification / जोखिम स्तरीकरण"),
             React.createElement("p", { style: styles.riskStratSubtitle }, "(please tick) / (कृपया सही का निशान लगाएं)"),
             React.createElement("div", { style: { ...styles.riskStratCheckboxes } },
                // Checkboxes for risk levels - link 'checked' to patient data if available
                createCheckboxField("Standard / स्‍टैंडर्ड", patient?.riskStratification === 'Standard', { label: { fontSize: '11px' } }), // Adjust style if needed
                createCheckboxField("Moderate / मध्यम", patient?.riskStratification === 'Moderate', { label: { fontSize: '11px' } }),
                createCheckboxField("High / उच्च", patient?.riskStratification === 'High', { label: { fontSize: '11px' } })
             ),
             // Signature specifically for Risk Stratification
             React.createElement("div", { style: styles.riskStratSignature },
                  React.createElement("div", { style: {...styles.signatureBox, width: "200px", display: "inline-block"} }), // Inline-block for right alignment
                  React.createElement("p", { style: styles.signatureLabel }, "Signature of Patient / मरीज़ के हस्ताक्षर")
             )
         ),
         // *** END: Risk Stratification Section ***
  
  
        // Specific Consent Checkboxes Section
        React.createElement("div", { style: { margin: "15px 0" } },
            React.createElement("p", { style: { fontWeight: "bold" } }, "Please tick and sign whichever is applicable / कृपया जो भी लागू हो टिक और हस्ताक्षर करें:"),
            createCheckboxField(
                React.createElement("span", {style:{fontSize:"12px"}},
                    "In view of the above-mentioned problems that are specific to me /my ward, I can understand that I am/my ward is very high risk case for anesthesia and surgery. Post operative ventilatory support and ICU care may needed. / ",
                    React.createElement("span", { style: styles.hindiText }, "उपर्युक्त समस्याओं को देखते हुए जो मेरे / मेरे वार्ड के लिए विशिष्ट हैं, मैं यह समझ सकता हूं कि मैं / मेरा वार्ड संज्ञाहरण और सर्जरी के लिए बहुत अधिक जोखिम वाला मामला है। पोस्ट ऑपरेटिव वेंटिलेटरी सपोर्ट और आईसीयू देखभाल की आवश्यकता हो सकती है।")
                ),
                patient?.consentOptions?.highRiskAcknowledged
            ),
            createCheckboxField(
                React.createElement("span", {style:{fontSize:"12px"}},
                    "I consent to the photography or televising of the surgery / procedure to be performed for the purpose of advancing medical education, or its publication in scientific journals, provided the patient identity is not revealed/disclosed. I also consent to the observation of the surgery/ procedure by qualified observers as may be authorized by Kidney Stone and Urology Hospital. / ",
                    React.createElement("span", { style: styles.hindiText }, "मैं, मेडिकल/चिकित्सा शिक्षा के एडवांसमेंट/उन्नति/आगे बढ़ाने के लिए की जाने वाली सर्जरी/प्रोसीजर की फोटोग्राफी/टेलिविजिंग, वैज्ञानिक पत्रिकाओं में इसके प्रकाशन की अनुमति देती/देता हूं, परंतु मेरी पहचान उजागर नहीं की जानी चाहिए। मैं, Kidney Stone and Urology हॉस्पिटल द्वारा यथा अधिकृत योग्य पर्यवेक्षक/ओबजर्वर द्वारा सर्जरी/प्रोसीजर की निगरानी करने के लिए भी सहमति देती/देता हूं।")
                ),
                patient?.consentOptions?.allowPhotography
            )
        ),
  
         // Final Patient Certification
        React.createElement("div", { style: { margin: "15px 0", textAlign: "justify" } },
          React.createElement("p", null,
            "I certify that I have received complete information and fully understood the above consent statement, that all of my questions have been answered to my satisfaction, that all blanks requiring insertion or completion were filled in, prior to the time of my signature, and that this consent is given with stable mind, freely, voluntarily and without reservation and misrepresentation. / ",
             React.createElement("span", { style: styles.hindiText }, "मैं प्रमाणित करता हूं कि मुझे पूरी जानकारी प्राप्त हो गई है और उपरोक्त सहमति कथन को पूरी तरह से समझ लिया गया है, कि मेरे सभी प्रश्नों का उत्तर मेरी संतुष्टि के लिए दिया गया है, कि सम्मिलन या पूर्णता की आवश्यकता वाले सभी रिक्त स्थान मेरे हस्ताक्षर के समय से पहले भरे गए थे, और यह सहमति स्थिर दिमाग के साथ, स्वतंत्र रूप से, स्वेच्छा से और आरक्षण और गलत बयानी के बिना दी गई है।")
          ),
          React.createElement("p", null,
              'Patient to write in her own words "I have understood and give my consent." / ',
              React.createElement("span", { style: styles.hindiText }, 'धैर्यवान अपने शब्दों में लिखने के लिए "मैं समझ गया हूं और अपनी सहमति देता हूं।"')
          ),
          React.createElement("div", { style: { borderBottom: "1px dotted black", minHeight: "25px", margin: "10px 0", padding: "5px"} }, patient?.patientStatementInOwnWords || "")
        ),
  
        // Final Authorization by Patient/Rep
         React.createElement("div", { style: { margin: "15px 0", textAlign: "justify" } },
           React.createElement("p", null, "To be filled by the patient or Doctor to document in patient's own words: / रोगी या डॉक्टर द्वारा रोगियों के स्वयं के शब्दों में दस्तावेज करने के लिए भरा जाना:"),
           React.createElement("p", null,
             "Knowing the above risk, on my own responsibility, I hereby authorize Dr. ", React.createElement("span", { style: styles.placeholderLine }, patient?.anesthesiologistName || ""),
             " and those he may designate as associates or assistants to go ahead on anesthesia and surgery at my own risk. All pros and cons have been explained and discussed with me. / ",
             React.createElement("span", { style: styles.hindiText }, "उपरोक्त जोखिम को जानना, अपनी जिम्मेदारी पर मैं एतद्द्वारा अधिकृत करता हूं डॉक्‍टर ", React.createElement("span", { style: styles.placeholderLine }, patient?.anesthesiologistName || ""), " और जिन्हें वह अपने जोखिम पर संज्ञाहरण और सर्जरी पर आगे बढ़ने के लिए सहयोगियों या सहायकों के रूप में नामित कर सकते हैं। सभी पेशेवरों और विपक्षों को समझाया गया है और मेरे साथ चर्चा की गई है।")
           )
         ),
  
  
        // Signature Section (Patient/Representative)
         React.createElement("div", { style: { ...styles.twoCol, marginTop: "30px"} },
            React.createElement("div", { style: styles.twoColItem },
                  React.createElement("div", { style: styles.signatureBox }),
                  React.createElement("p", { style: styles.signatureLabel }, "Signature of Patient / मरीज का हस्ताक्षर"),
                  React.createElement("p", { style: {marginTop: "5px"} }, "Name: ", patient?.patient?.name || "....................................")
              ),
              React.createElement("div", { style: styles.twoColItem },
                  React.createElement("div", { style: styles.signatureBox }),
                  React.createElement("p", { style: styles.signatureLabel }, "Signature of Representative of Patient / मरीज के प्रतिनिधि के हस्ताक्षर"),
                   React.createElement("p", { style: {marginTop: "5px"} }, "Name: ", patient?.representativeName || "...................................."),
                   React.createElement("p", { style: {marginTop: "5px"} }, "Relationship / मरीज के साथ संबंध: ", patient?.representativeRelationship || "......................")
              )
         ),
          React.createElement("p", { style: { fontSize: "10px", fontStyle: "italic", marginTop: "0", textAlign: "right"} }, "Representative of Patient to sign for and on behalf of patient. If patient is a minor, unconscious, or otherwise lacks the ability to make informed decision. / रोगी के प्रतिनिधि के लिए और रोगी की ओर से हस्ताक्षर करने के लिए। यदि रोगी नाबालिग है, बेहोश है, या अन्यथा सूचित निर्णय लेने की क्षमता का अभाव है।"),
  
          React.createElement("div", { style: { ...styles.twoCol, marginTop: "10px"} },
               React.createElement("p", null, "Date/ तारीख: ", formatDate(patient?.consentSignatureDate) || "......................"),
               React.createElement("p", null, "Time/ समय: ", formatTime(patient?.consentSignatureDate) || "......................")
           ),
  
  
        // Declaration by Anesthesiologist
        React.createElement("div", { style: { margin: "25px 0 10px 0", borderTop: "1px dashed #999", paddingTop: "15px" } },
          React.createElement("h4", { style: styles.sectionTitle }, "DECLARATION BY THE ANESTHESIOLOGIST PROVIDING INFORMATION FOR THIS CONSENT"),
          React.createElement("p", { style: { textAlign: "justify"} },
            "I declare that I have explained the nature of general and/or regional anesthesia to be given and discussed the risk that particularly concern this patient. I have explained the patient's condition, the procedure and the risk, likely consequences if those risks occur and the significant risk and problems specific to this patient. I have given the patient/ Guardian an opportunity to ask questions about any of the above matters and raise any other concerns, I have answered as fully as possible. I am of the opinion that the Patient/ Substitute decision maker understood the above information. / ",
             React.createElement("span", { style: styles.hindiText }, "मैं घोषणा करता हूं कि मैंने सामान्य और / या रेजिनल एनेस्थीसिया की प्रकृति को समझाया है और विशेष रूप से इस रोगी को चिंतित करने वाले जोखिम पर चर्चा की है। मैंने रोगी की स्थिति, प्रक्रिया और जोखिम, संभावित परिणामों की व्याख्या की है यदि वे जोखिम होते हैं और इस रोगी के लिए विशिष्ट महत्वपूर्ण जोखिम और समस्याएं। मैंने रोगी/अभिभावक को उपरोक्त मामलों में से किसी के बारे में प्रश्न पूछने और उठाने का अवसर दिया है। किसी भी अन्य चिंताओं, मैंने यथासंभव पूरी तरह से जवाब दिया है। मेरी राय है कि रोगी/स्थानापन्न निर्णय उपरोक्त जानकारी को समझ लेता है।")
          )
        ),
  
         // Signature Section (Anesthesiologist)
         React.createElement("div", { style: { ...styles.twoCol, marginTop: "20px"} },
              React.createElement("div", { style: styles.twoColItem },
                  React.createElement("div", { style: styles.signatureBox }),
                  React.createElement("p", { style: styles.signatureLabel }, "Signature of Anesthesiologist / डॉक्टर का हस्ताक्षर"),
                  React.createElement("p", { style: {marginTop: "5px"} }, "Name / नाम: ", patient?.anesthesiologistName || "...................................."),
                  React.createElement("p", { style: {marginTop: "5px"} }, "Designation / पद: ", patient?.anesthesiologistDesignation || "......................")
              ),
             React.createElement("div", { style: styles.twoColItem },
                  React.createElement("p", null, "Date/ तारीख: ", formatDate(patient?.anesthesiologistSignatureDate) || "......................"),
                  React.createElement("p", null, "Time/ समय: ", formatTime(patient?.anesthesiologistSignatureDate) || "......................")
             )
         )
  
      ); // End of main React.createElement("div", ...)
    }`; // End of function definition // End of template literal string